<template>
  <div />
</template>

<script>
  import { deleteCookie, isLeadError, isServer } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'
  import requests from '../../../../requests'
  import { metric } from '../../../../utils'

  export default {
    name: 'EventListener',
    data: () => ({
      myAccountUrl: null,
      updateHandler: null
    }),
    computed: {
      ...mapGetters('translations', ['getMyAccountUrl', 'getMyAccountOffersUrl']),
      ...mapGetters('form', ['getForm'])
    },
    mounted () {
      this.killUpdate()

      this.EVENT_BUS.$on('application:update', () => { this.triggerUpdate() })
      this.EVENT_BUS.$on('application:kill-update', () => { this.killUpdate() })
      this.EVENT_BUS.$on('application:finish', () => { this.triggerFinish() })
      this.EVENT_BUS.$on('application:go-to-my-account', () => { this.goToMyAccount() })
      this.EVENT_BUS.$on('application:go-to-my-account-offers', () => { this.goToMyAccountOffers() })
      this.EVENT_BUS.$on('storage:clear', (reload) => { this.clearStorage(reload) })
      this.EVENT_BUS.$on('api:error', ({ errorName = null, error = null } = {}) => { this.apiError(errorName, error) })
      this.EVENT_BUS.$on('api:customError', ({ code }) => { this.apiCustomError(code) })

      window.addEventListener('resize', this.onResize)
      window.addEventListener('scroll', this.onScroll)
      this.onResize()
    },
    beforeDestroy () {
      this.EVENT_BUS.$off('application:update')
      this.EVENT_BUS.$off('application:kill-update')
      this.EVENT_BUS.$off('application:go-to-my-account')
      this.EVENT_BUS.$off('application:go-to-my-account-offers')
      this.EVENT_BUS.$off('storage:clear')
      this.EVENT_BUS.$off('api:error')
      this.EVENT_BUS.$off('api:customError')

      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      ...mapActions('messages', ['setModalMessage']),
      ...mapActions('misc', ['setMisc']),
      onResize () {
        const width = window.innerWidth ||
          document.documentElement?.clientWidth ||
          document.body?.clientWidth

        this.setMisc({ isMobile: (width <= 768) })
        this.setMisc({ isTablet: (width > 768 && width <= 992) })
        this.setMisc({ isDesktop: (width > 992) })
      },
      onScroll () {
        this.setMisc({ scroll: window.scrollY })
      },
      async clearStorage (reload = false) {
        if (isServer()) return true

        setTimeout(() => {
          window.localStorage.removeItem(`zmarta_form_${this.VERTICAL}`)
          window.localStorage.removeItem(`zmarta_tracking_${this.VERTICAL}`)
          window.sessionStorage.removeItem(`zmarta_form_${this.VERTICAL}`)
          window.sessionStorage.removeItem(`zmarta_session_${this.VERTICAL}`)
          window.sessionStorage.removeItem('zmarta_session')
          deleteCookie('zmarta_consumer_loan_values')
        }, 0)

        if (reload) {
          await requests.serviceAuth.oauth.reset({
            market: this.MARKET,
            vertical: this.VERTICAL
          })

          window.location.reload()
        }
      },
      triggerUpdate () {
        this.killUpdate()

        this.updateHandler = setTimeout(() => {
          if (this.CL) {
            this.$store.dispatch('cl/application/updateApplication')
          } else if (this.SME) {
            this.$store.dispatch('sme/application/updateApplication')
          }
        }, 2000)
      },
      triggerFinish () {
        if (this.CL) {
          this.$store.dispatch('cl/application/finishApplication')
        } else if (this.SME) {
          this.$store.dispatch('sme/application/finishApplication')
        }
      },
      killUpdate () {
        clearTimeout(this.updateHandler)
        this.updateHandler = null
      },
      async goToMyAccount () {
        metric('application', { action: 'form', status: 'end' })

        const url = this.getMyAccountUrl
        window.appNo = null

        await this.clearStorage()
        setTimeout(() => {
          window.location.href = url
        }, 1000)
      },
      async goToMyAccountOffers () {
        metric('application', { action: 'form', status: 'end' })

        const url = this.getMyAccountOffersUrl
        window.appNo = null

        await this.clearStorage()
        setTimeout(() => {
          window.location.href = url
        }, 1000)
      },
      apiError (name, error) {
        const showError = !isLeadError(name)
        this.setModalMessage({ group: 'api', name, show: showError })
        if (showError && error) console.error(error)
      },
      apiCustomError (code) {
        this.setModalMessage({ group: 'customErrors', name: code, show: true })
      }
    }
  }
</script>
